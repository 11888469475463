<template lang="pug">
  .impersonate-button(
    @click="stopImpersonating"
    :class="{ 'impersonate-button_disabled': isLoading }"
  ) {{ $t("header.stop_impersonating") }}
</template>

<script>
  // misc
  import { api } from "@/config"

  export default {
    data() {
      return {
        isLoading: false
      }
    },
    methods: {
      async stopImpersonating() {
        try {
          if (this.isLoading) return

          this.isLoading = true
          await api.post("/maestro/users/stop_impersonating", {})
          window.location.replace("/")
        } finally {
          this.isLoading = false
        }
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .impersonate-button
    cursor: pointer
    background: $default-red
    color: $default-white
    padding: 3px 10px
    border-radius: 3px
    transition: all 0.1s linear

    &:hover
      background: darken($default-red, 10)

    &_disabled
      opacity: .5
      cursor: not-allowed
</style>
